<template>
    <div class="modal add_clients" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header">
                <h3>Assessment History <span v-if="!progressLoader && progress.batch">({{ moment(progress.batch.updated_at).format('ll | LT') }})</span></h3>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body" v-if="progressLoader">
                <quote-loader />
            </div>
            <div class="modal_body" v-else>
                <div class="profile_status">
                    <div class="score-board" :style="`background: ${colors[scoreTag]}`">
                        <h3>Your Thrive Score</h3>
                        <h6>Your business is : <span>{{ scoreTag }}</span></h6>
                        <h5 v-if="scoreTag === 'Thriving'">Based on your Thrive Score your business is thriving in all areas. You are highly regarded in the market, you enjoy abundant leads and sales, and you are growing like a weed. Keep it up!</h5>
                        <h5 v-if="scoreTag === 'Healthy'">Based on your Thrive Score your business is enjoying a healthy amount of market awareness, new leads, abundant sales, and good retention. Small improvements will help you achieve optimum scalability.</h5>
                        <h5 v-if="scoreTag === 'Average'">Based on your Thrive Score your business is average when it comes to getting seen, making sales, keeping clients, and scaling. You can expect 0-5% growth and an average return from your business.</h5>
                        <h5 v-if="scoreTag === 'Unhealthy'">Based on your Thrive Score your business is at risk of increased churn, decreased sales, and an overall struggle to maintain stability.</h5>
                        <h5 v-if="scoreTag === 'Dying'">Based on your Thrive Score you can expect a downward trend in your lead generation, sales, and program delivery systems that could lead to fatal outcomes for your business</h5>
                        <h5 v-if="scoreTag === 'Fatal'">Based on your Thrive Score it is urgent that you start repairing broken areas of your business to avoid the inevitable collapse of your company and your inability to scale.</h5>
                    </div>
                    <div class="progress-circle">
                        <circle-progress :size="170" :percent="progress.score" :stroke-width="8" :color="colors[scoreTag]" empty-color="#f4f4f4" />
                    </div>
                    <div class="category-score">
                        <ul>
                            <li v-for="(scoreObj, category) of progress.categories" :key="category">
                                <h5>{{ category }}</h5>
                                <div class="cont">
                                    <div>
                                        <h6 v-for="(subCategoryScore, subCategory) of scoreObj.sub_categories" :key="subCategory">{{ subCategory }}: <span>{{ subCategoryScore }}%</span></h6>
                                    </div>
                                    <circle-progress :size="60" :percent="scoreObj.score" :stroke-width="5" :color="colors[getScoreTag(scoreObj.score)]" empty-color="#f4f4f4" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="vff answers">
                    <div class="f-container">
                        <div class="f-form-wrap">
                            <div class="category-box" v-for="category of Object.keys(questionsArray)" :key="category">
                                <h2>{{ category }}</h2>
                                <div class="sub-category-box" v-for="subCategory of Object.keys(questionsArray[category])" :key="subCategory">
                                    <h3>{{ subCategory }}</h3>
                                    <div class="vff-animate q-form q-is-active f-fade-in-up field-multiplechoice" v-for="(question, q) of questionsArray[category][subCategory]" :key="q">
                                        <div class="q-inner">
                                            <div class="">
                                                <div class="fh2">
                                                    <div class="f-label-wrap">
                                                        <span class="f-key">{{ ++q }}.&nbsp;</span>
                                                        <span class="f-text">{{ question.title }}</span>
                                                    </div>
                                                    <div class="f-answer f-full-width">
                                                        <div class="f-radios-wrap">
                                                            <ul class="f-radios" role="listbox">
                                                                <li :class="{'f-selected': progress.answers[question.id] == option.id }" aria-label="Press A to select" role="option" v-for="(option, o) of question.options" :key="o">
                                                                    <div class="f-label-wrap">
                                                                        <span class="f-key">{{ quextionIndex(o) }}</span>
                                                                        <span class="f-label">{{ option.label }}</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const CircleProgress = defineAsyncComponent(() => import('@/components/CircleProgress'))

    import moment from 'moment'

    export default {
        name: 'Setup Guide Question History',

        data () {
            return {
                colors: {
                    Thriving: '#50A75E',
                    Healthy: '#2D80ED',
                    Average: '#F2A32F',
                    Unhealthy: '#ECD72C',
                    Dying: '#C13F52',
                    Fatal: '#1B2437',
                },
                moment,
                scoreTag: '',
                questionsArray: [],
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            CircleProgress,
        },

        watch: {
            progress (progress) {
                const vm = this;

                vm.scoreTag = vm.getScoreTag(progress.score);
            },

            questions (questions) {
                const vm = this;

                questions.questions.forEach((question) => {
                    if (typeof vm.questionsArray[question.category] !== 'object') {
                        vm.questionsArray[question.category] = [];
                    }

                    if (typeof vm.questionsArray[question.category][question.sub_category] !== 'object') {
                        vm.questionsArray[question.category][question.sub_category] = [];
                    }

                    vm.questionsArray[question.category][question.sub_category].push(question);
                });
            },
        },

        computed: mapState({
            progress: state => state.setupGuideModule.progress,
            progressLoader: state => state.setupGuideModule.progressLoader,
            questions: state => state.setupGuideModule.questions,
        }),

        mounted () {
            const vm = this;

            vm.questionsArray = [];

            if (vm.questions.questions) {
                vm.questions.questions.forEach((question) => {
                    if (typeof vm.questionsArray[question.category] !== 'object') {
                        vm.questionsArray[question.category] = [];
                    }

                    if (typeof vm.questionsArray[question.category][question.sub_category] !== 'object') {
                        vm.questionsArray[question.category][question.sub_category] = [];
                    }

                    vm.questionsArray[question.category][question.sub_category].push(question);
                });
            }
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            getScoreTag (score) {
                const vm = this;
                let scoreTag = 'Fatal';

                if (score > 90) {
                    scoreTag = 'Thriving';
                }

                if (score >= 80 && score <= 90) {
                    scoreTag = 'Healthy';
                }

                if (score > 65 && score < 80) {
                    scoreTag = 'Average';
                }

                if (score >= 50 && score <= 65) {
                    scoreTag = 'Unhealthy';
                }

                if (score >= 35 && score < 50) {
                    scoreTag = 'Dying';
                }

                if (score < 35) {
                    scoreTag = 'Fatal';
                }

                return scoreTag;
            },

            quextionIndex (index) {
                const indexes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

                return indexes[index];
            },
        },
    };
</script>

<style scoped>
    .modal.add_clients .modal_container,
    .modal .modal_header,
    .modal_body {
        padding: 15px;
    }

    .modal_body {
        max-height: 70vh;
        overflow-y: scroll;
    }

    .profile_status {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .progress-circle {
        margin: 0 auto;
        padding: 10px;
        justify-content: center;
        display: flex;
        width: 20%;
    }

    .category-score {
        width: 50%;
    }

    .profile_status::-webkit-scrollbar {
        display: none;
    }

    .profile_status h3 {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
    }

    .profile_status h4 {
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 5px;
    }

    .profile_status h5 {
        font-size: 13px;
        line-height: 19px;
        font-weight: 300;
        color: #808080;
        margin: 15px 0;
    }

    .profile_status h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        margin: 10px 0;
    }

    .profile_status p {
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 30px 0 0;
    }

    .profile_status ol {
        padding-left: 15px;
    }

    .profile_status ol li {
        list-style-type: decimal;
        font-size: 13px;
        line-height: 20px;
        padding: 6px 0;
    }

    .profile_status ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .profile_status ul li {
        margin-bottom: 15px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #e9e9e9;
        padding: 15px;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.02);
        flex: 0 0 48%;
        margin: 1%;
    }

    .profile_status ul li h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .profile_status ul li .cont {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .profile_status ul li h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 4px 0;
        margin: 0;
    }

    .profile_status ul li h6 span {
        color: #2f7eed;
        font-weight: 500;
        padding-left: 5px;
    }

    .profile_status .score_circle {
        width: 160px;
        height: 160px;
        padding: 10px;
        margin: 0 auto 40px auto;
    }

    .profile_status .score_circle .inner_circle .score {
        font-size: 30px;
        line-height: 40px;
        font-weight: 500;
        color: #121525;
    }

    .profile_status .score_circle .inner_circle .score span {
        font-size: 20px;
    }

    .profile_status .score_circle h5 {
        font-size: 14px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 0;
    }

    .profile_status ul li .score_circle {
        width: 50px;
        height: 50px;
        padding: 4px;
        margin: 0;
    }

    .profile_status ul li .score_circle .inner_circle {
        background: #fff;
    }

    .profile_status ul li .score_circle .inner_circle .score {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
    }

    .profile_status ul li .score_circle .inner_circle .score span {
        font-size: 10px;
    }

    .score-board {
        padding: 15px;
        border-radius: 5px;
        width: 30%;
    }

    .score-board h3, .score-board h6, .score-board h5 {
        color: #FFF;
    }

    .answers.vff {
        padding: 40px 0 0;
    }

    .answers.vff .f-container {
        border-bottom: 0px solid #FFF;
    }

    .answers.vff .f-container .f-form-wrap {
        padding: 40px 0 40px;
    }

    .answers.vff .f-container .f-form-wrap {
        width: 100%;
        max-width: 100%;
    }

    .answers.vff .f-container, header.vff-header .f-container {
        padding: 0;
    }

    .answers.vff .f-radios-wrap {
        width: 100%;
    }

    .answers.vff .f-radios-wrap ul.f-radios {
        display: flex;
        max-width: 100%;
        margin: 0 -1%;
    }

    .answers.vff .f-radios-wrap ul.f-radios li {
        flex: 0 0 18%;
        margin: 1%;
        cursor: alias;
    }

    .answers.vff .f-answer.f-full-width {
        margin-top: 0px;
    }

    .answers.vff .fh2 {
        font-size: 16px;
        line-height: 22px;
    }

    .answers.vff .category-box h2 {
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
        padding: 0;
    }

    .answers.vff .category-box .sub-category-box {
        background: #fff;
        border-radius: 5px;
        border: 1px solid #e9e9e9;
        padding: 15px;
        box-shadow: 0 1px 15px rgb(0 0 0 / 2%);
        margin-bottom: 20px;
    }

    .answers.vff .category-box .sub-category-box h3 {
        text-align: center;
        margin-bottom: 20px;
    }

    .answers.vff ul.f-radios li {
        font-size: 13px;
        line-height: 20px;
    }

    .modal_body .quote_wpr {
        height: 60vh;
        justify-content: center;
    }

    @media only screen and (device-width: 768px), only screen and (max-width: 768px) {
        .score-board {
            width: 60%;
        }

        .progress-circle {
            width: 40%
        }

        .category-score {
            width: 100%;
            margin-top: 20px;
        }
    }

    @media only screen and (device-width: 425px), only screen and (max-width: 425px) {
        .score-board {
            width: 100%;
        }

        .progress-circle {
            width: 100%;
            margin-top: 20px;
        }

        .category-score {
            width: 100%;
            margin-top: 20px;
        }

        .profile_status ul li {
            flex: 100%;
            margin: 1%;
        }
    }
</style>
